<template>
    <v-container class="py-0">
        <v-row class="mt-4 px-8">
            <v-col cols="12" lg="6">
                <Spotlight role="all" />
            </v-col>
            <v-col cols="12" lg="6">
                <div class="text-overline">Actions</div>
                <v-list rounded>
                    <v-list-item-group color="primary">
                        <v-list-item
                            v-for="metaCampaign of organisation.metaCampaigns"
                            :key="metaCampaign.id"
                            @click="onMetaCampaign(metaCampaign.id)"
                        >
                            <v-list-item-icon>
                                <v-icon large left color="primary" class="pr-4">
                                    mdi-{{
                                        metaCampaign.options.icon
                                            ? metaCampaign.options.icon
                                            : 'format-list-checks'
                                    }}
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title
                                    v-text="metaCampaign.title"
                                ></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>
        </v-row>
        <v-fab-transition>
            <v-btn
                fab
                fixed
                color="primary"
                large
                dark
                bottom
                right
                @click="onScan"
            >
                <v-icon>mdi-qrcode-scan</v-icon>
            </v-btn>
        </v-fab-transition>
        <v-snackbar
            :value="showInstallationInstructions"
            timeout="-1"
            vertical
            app
            bottom
            max-width="375"
        >
            <v-row>
                <v-col
                    cols="12"
                    class="popup-header d-flex align-start justify-space-between"
                >
                    <p class="text-h5">Add to Home Screen</p>
                    <v-btn
                        text
                        icon
                        @click="() => dismissInstallationInstructions(false)"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <p>Add Rhythm to your home screen for easy access.</p>
                    <div>
                        <v-icon>mdi-apple</v-icon> Tap
                        <v-icon>mdi-export-variant</v-icon> icon > Add to Home
                        Screen.
                    </div>
                    <div>
                        <v-icon>mdi-android</v-icon> Tap
                        <v-icon>mdi-dots-vertical</v-icon> icon > Add to Home
                        Screen.
                    </div>
                </v-col>
            </v-row>

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="() => dismissInstallationInstructions(true)"
                >
                    Dismiss Permanently
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
export default {
    components: {
        Spotlight: () => import('@/components/spotlight/Spotlight'),
    },
    data() {
        return {
            showInstallationInstructions: false,
        }
    },
    created() {
        this.showInstallationInstructions = !localStorage.getItem(
            'dismissInstallationInstructions'
        )
    },
    methods: {
        onScan() {
            this.$router.push('/scan')
        },
        onMetaCampaign(metaCampaignId) {
            this.$router.push(`/campaign/${metaCampaignId}`)
        },
        dismissInstallationInstructions(permanent = false) {
            this.showInstallationInstructions = false
            if (permanent) {
                localStorage.setItem('dismissInstallationInstructions', true)
            }
        },
    },
    computed: {
        user() {
            const { name, email, roles } = this.$store.getters['auth/getUser']
            return {
                name,
                email,
                roles,
            }
        },
        organisation() {
            const name = this.$store.getters['organisation/getName']
            const metaCampaigns = this.$store.getters[
                'organisation/getMetaCampaigns'
            ]
            return {
                name,
                metaCampaigns,
            }
        },
    },
}
</script>

<style scoped></style>
